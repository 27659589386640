.contact-form {
  margin-bottom: 100px;
}

.contact-form .left-section .contact-animation {
  margin-top: 7rem;
}

.contact-form .left-section .line {
  width: 90%;
}

.contact-form .left-section .contact-image img {
  width: 80%;
}

.contact-form .left-section .contact-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  gap: 2rem;
}

.contact-form .left-section .contact-details .contact-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
}

.contact-form .left-section .contact-details .contact-info .contact-heading,
.contact-form .left-section .contact-details .social-heading {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--primaryColor);
  margin-top: 1rem;
}

.contact-form .left-section .contact-details .contact-info .contact-content {
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
}

.contact-form
  .left-section
  .contact-details
  .contact-info
  .contact-content
  .icon
  i {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  border: 1px solid var(--borderColor);
  padding: 8px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: var(--bgColor);
  color: var(--textColor);
}

.contact-form .left-section .contact-details .contact-info .contact-content h2 {
  font-size: 1.15rem;
  margin-bottom: 4px;
  color: var(--textColor);
  font-weight: 500;
}

.contact-form .left-section .contact-details .contact-info .contact-content a {
  font-size: 1rem;
}

.contact-form .left-section .contact-details .social-info {
  width: 100%;
}

.contact-form .left-section .contact-details .social-info .social-accounts {
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;
  gap: 10px;
  width: 100%;
}

.contact-form .left-section .contact-details .social-info .social-accounts a {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.3rem;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.8);
  text-decoration: none;
  transition: all 0.2s ease-in;
}

.contact-form
  .left-section
  .contact-details
  .social-info
  .social-accounts
  a:hover {
  transform: scale(1.05);
}

/* Right Section */
.contact-form .right-section {
  margin-top: 6rem;
}

.contact-form .right-section .description {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1.85rem;
  margin-bottom: 1.85rem;
}

.contact-form .right-section .description h1 {
  font-size: 2rem;
  font-weight: bold;
  color: var(--primaryColor);
}

.contact-form .right-section .description p {
  font-size: 1rem;
  font-weight: normal;
  color: var(--textLight);
}

@media (max-width: 1400px) {
  .contact-form .right-section {
    margin-top: 1.5rem;
  }

  .contact-form .left-section .contact-animation {
    margin-top: 3rem;
  }
}

@media (max-width: 1200px) {
  .contact-form .left-section .contact-details {
    gap: 3rem;
  }

  .contact-form .left-section .contact-details .contact-info {
    gap: 1.5rem;
  }

  .contact-form .left-section .contact-details .social-info {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .contact-form .left-section .contact-animation {
    margin-top: 6rem;
  }
}

@media (max-width: 992px) {
  .contact-form {
    margin-top: 0;
  }

  .contact-form .left-section .line {
    width: 100%;
  }

  .contact-form .left-section .divider {
    display: none;
  }

  .contact-form .left-section .contact-image img {
    width: 100%;
  }

  .contact-form .left-section .contact-animation {
    margin-top: 2.5rem;
  }
}

@media (max-width: 576px) {
  .contact-form {
    margin-top: 30px;
  }
}
