@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Varela+Round&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css");

/* __________ CSS VARIABLES  __________ */
:root {
  /* _____ Action _____ */
  --primaryColor: #d0ae1f;
  --primaryHoverColor: #9d8317;
  --secondaryColor: #ffd301;

  /* _____ Text _____ */
  --textColor: #2f2d47;
  --textLight: #67667c;

  /* _____ BG _____ */
  --bgBody: #fafcff;
  --bgColor: #f4f4fc;
  --bgLight: #fdf9ff;
  --dashboardActiveLinkBg: rgba(157, 131, 23, 0.15);

  /* _____ Border _____ */
  --borderColor: #d4cad9;
  --borderLight: #dfd8e3;
  --borderCool: #ced4da;

  /* _____ Shadow _____ */
  --shadow: 0px 23px 21px -8px rgba(136, 160, 255, 0.15);
  --shadowAll: 0px 2px 15px rgba(0, 0, 0, 0.15);
  --materialShadow: 0 2.5px 4px -1px rgba(32, 31, 31, 0.3);
  --materialShadowBottom: 0 2.5px 2px -1px rgba(32, 31, 31, 0.45);
}

* {
  margin: 0px;
  padding: 0px;
}

body {
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
  background: var(--bgBody);
}

::-webkit-scrollbar {
  width: 4px !important;
  height: 4px !important;
}

::-webkit-scrollbar-thumb {
  background: var(--primaryColor) !important;
}

::-webkit-scrollbar-track {
  background: transparent !important;
}

.cursor {
  cursor: pointer;
}

.section {
  padding: 45px 0px;
}

.snackbar {
  right: 20px !important;
  left: inherit !important;
  z-index: 100000000 !important;
}

a:hover {
  text-decoration: none;
}

/* badge */
.badge {
  display: inline-block;
  padding: 7px 10px;
  border-radius: 20px;
}

.badge.bg-success {
  background: #e5f8ed !important;
  color: #16b858 !important;
}

.badge-success {
  color: #00b853;
  background: #e5f8ed;
}

.badge-primary {
  background: #2bbef9 !important;
}

/* border */
.border-effect {
  position: relative;
  color: var(--primaryHoverColor);
  text-decoration: none;
}

.border-effect:after {
  display: block;
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0;
  height: 1px;
  background-color: var(--primaryHoverColor);
  content: "";
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.border-effect:hover {
  text-decoration: none;
  color: var(--primaryHoverColor);
}

.border-effect:hover:after {
  width: 100%;
}

/* bg */
.bg-blue {
  background: #6d4aae;
}

.bg-red {
  background: #ed174a !important;
}

/* button */
button {
  text-transform: capitalize !important;
}

button svg {
  color: rgba(0, 0, 0, 0.8);
  /* color: #fff; */
}

.btn-blue {
  background: #6d4aae !important;
  color: #fff !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;
  padding: 5px 20px !important;
}

.btn-blue:hover {
  background: #53348c !important;
}

.btn-big {
  font-size: 15px !important;
  padding: 8px 30px !important;
  min-width: auto !important;
  font-weight: bold !important;
}

.btn-round {
  border-radius: 30px !important;
}

.btn-blue svg {
  font-size: 22px;
}

.btn-sml {
  font-size: 12px !important;
  color: #000;
  text-transform: capitalize !important;
}

.btn-sml svg {
  font-size: 20px;
}

.btn-circle {
  border-radius: 50% !important;
  min-width: auto !important;
}

/* text */
.text-red {
  color: #ed174a !important;
}

.text-blue {
  color: #53348c !important;
}

.text-light {
  color: #8b8b8b !important;
}

.text-sml {
  font-size: 14px;
}

/* _____________________ OVER-RIDDED _______________________ */

/* Overriding swiper classes */
.swiper-button-next {
  right: 3% !important;
  position: absolute;
  top: 45%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px !important;
  height: 45px !important;
  background: #fff;
  border-radius: 100%;
  font-family: FontAwesome !important;
  font-weight: bold;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%) !important;
}

.swiper-button-prev {
  left: 3% !important;
  position: absolute;
  top: 45%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px !important;
  height: 45px !important;
  background: #fff;
  border-radius: 100%;
  font-family: FontAwesome !important;
  font-weight: bold;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%) !important;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  color: #000 !important;
  opacity: 0.8;
  font-size: 17px !important;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  background: #e7e7e7 !important;
}

.swiper {
  padding-right: 2px !important;
}

/* Overriding slick classes */
.slick-dots li button:focus,
.slick-dots li button:hover {
  outline: none !important;
}

.slick-dots li button:before {
  opacity: 0.5 !important;
}

.slick-dots li.slick-active button:before {
  opacity: 1 !important;
}

.slick-dots li button:before {
  font-size: 8px !important;
}

/* Overriding range slider classes */
.range-slider {
  height: 5px !important;
}

.range-slider .range-slider__thumb,
.range-slider .range-slider__range {
  background: #6d4aae !important;
}

.range-slider .range-slider__thumb {
  width: 15px !important;
  height: 15px !important;
}

/* Overriding mui classes */
.MuiRadio-root {
  zoom: 85% !important;
}

.MuiDialog-root {
  z-index: 100000 !important;
}

button .MuiCircularProgress-root {
  width: 30px !important;
  height: 30px !important;
}

.MuiDialog-container .MuiPaper-root {
  max-height: calc(100% - 25px);
}

.css-10nakn3-MuiModal-root-MuiPopover-root-MuiMenu-root {
  z-index: 1000000 !important;
}

.css-10nakn3-MuiModal-root-MuiPopover-root-MuiMenu-root .MuiPaper-elevation {
  max-height: 350px !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  padding: 2px !important;
}

.css-ahj2mt-MuiTypography-root {
  font-size: 13px !important;
}

.css-i4bv87-MuiSvgIcon-root {
  width: 20px !important;
  height: 20px !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #6d4aae !important;
}

/* Media Query */
@media only screen and (min-width: 320px) and (max-width: 992px) {

  body,
  html {
    overflow-x: hidden;
  }

  body {
    padding-bottom: 45px !important;
  }

  table th {
    white-space: nowrap !important;
  }
}