.invoice-main-box {
    margin-top: 12px;
    width: 800px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    font-family: Arial, sans-serif;
    background-color: #fff;
}

.invoice-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.invoice-logo .invoice-logo-image {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    border: 1px solid var(--borderColor);
    background: rgba(0, 0, 0, 0.8);
}


.invoice-company-name {
    width: 60%;
    text-align: right;
}

.invoice-company-name>h1 {
    font-size: 1.5rem;
}

.invoice-addresses {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.invoice-addresses div {
    width: 45%;
}

.invoice-orderid-invoice {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.invoice-product-table table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.invoice-product-table th,
.invoice-product-table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
}

.invoice-transaction-details,
.invoice-total {
    margin-bottom: 10px;
}

.invoice-total {
    font-weight: bold;
}

h3 {
    font-size: 1rem;
    font-weight: bold;
}

.invoice-subheading {
    font-size: 1rem;
    font-weight: bold;
}

.invoice-transaction-details,
.invoice-total {
    display: flex;

    align-items: center;
    justify-content: space-between;
}

.print-button {
    border: none;
    border-radius: 8px;
    float: right;
    font-size: 0.5rem;
    padding: 6px 8px;
    min-width: 64px;
    background-color: var(--primaryColor);
}

.print-button:hover {
    background-color: var(--primaryHoverColor);
}

.print-button-box {
    width: 100%;

    height: 10px;
}

@media print {
    body {
        margin: 0;
        padding: 0;
    }

    .invoice-main-box {
        width: 210mm;
        /* A4 width */
        height: auto;
        margin: 0 auto;
        padding: 20mm;
        /* Add padding for better readability */
        page-break-after: always;
        font-size: 12px;
        /* Optimize font for print */
        border: none;
        /* No borders during print */
    }

    .print-button {
        display: none;
        /* Hide print button in print */
    }
}

@media screen and (max-width: 768px) {
    .invoice-main-box {
        width: 95%;
        /* Adjust width for mobile screens */
        padding: 10px;
        font-size: 14px;
        /* Larger font for readability */
    }

    .invoice-header,
    .invoice-addresses,
    .invoice-orderid-invoice {
        flex-direction: column;
        /* Stack elements vertically */
        text-align: left;
    }

    .invoice-logo img {
        margin: 0 auto;
        display: block;
    }

    .invoice-product-table table {
        font-size: 12px;
        /* Adjust table font for smaller screens */
    }
}

.invoice-main-box {
    margin-top: 12px;
    width: 800px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    font-family: Arial, sans-serif;
    background-color: #fff;
    font-size: 14px;
    /* Base font size */
}