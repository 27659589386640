.orderTable th,
.orderTable td {
  white-space: nowrap;
}

.orderTable .img {
  width: 70px;
  height: 40px;
  overflow: hidden;
}
.orderTable .img img {
  width: 100%;
}

.productModal h4 {
  font-size: 20px !important;
  color: rgba(0, 0, 0, 0.8) !important;
}

.productModal .MuiPaper-elevation {
  padding: 25px;
  position: relative;
  max-width: 900px !important;
  width: 900px !important;
}

.close_ {
  position: absolute !important;
  top: 10px;
  right: 10px;
  color: #000 !important;
  min-width: 40px !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  background: #f1f1f1 !important;
}

.close_ svg {
  font-size: 22px;
  color: #000 !important;
  opacity: 0.7;
}

@media only screen and (min-width: 320px) and (max-width: 992px) {
  .productModal .MuiPaper-elevation {
    width: 100% !important;
    max-width: 900px !important;
    margin: 10px !important;
  }
}
