.about-collage {
  margin-top: 100px;
}

.about-collage .feature-box .divider {
  margin: 3rem 0;
  background: var(--borderCool);
  opacity: 0.5;
  height: 2px;
}

.about-collage .about {
  align-items: center;
}

.about-collage .about-row {
  flex-direction: row;
}

.about-collage .about-row-reverse {
  flex-direction: row-reverse;
}

.about-collage .about .content-box .heading {
  font-size: 1.75rem;
  font-weight: 500;
  color: var(--textHeading);
  margin-bottom: 1.5rem;
}

.about-collage .about .content-box .description {
  font-size: 1rem;
  text-align: justify;
  margin-bottom: 1.5rem;
  color: var(--textColor);
}

.about-collage .about .content-box .list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1.5rem;
}

.about-collage .about .content-box .list .list-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}

.about-collage .about .content-box .list .list-item .item {
  color: var(--textLight);
}

.about-collage .about .content-box .list .list-item .icon {
  padding: 5px;
  min-width: 28px;
  min-height: 28px;
  max-width: 28px;
  max-height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(208, 174, 31, 0.6);
  color: var(--primaryColor);
  background: rgba(208, 174, 31, 0.35);
}

/* Image */
.about-collage .about .image-box.left-margin {
  margin-left: 2rem;
}

.about-collage .about .image-box.right-margin {
  margin-right: 2rem;
}

.about-collage .about .image-box .image {
  width: 100%;
  border-radius: 8px;
}

@media (max-width: 992px) {
  .about-collage .about .image-box {
    margin-bottom: 2rem;
  }

  .about-collage .about .image-box.left-margin {
    margin-left: 0;
  }

  .about-collage .about .image-box.right-margin {
    margin-right: 0;
  }
}
