.product_Listing_Page {
  padding: 25px 0px;
}
.product_Listing_Page .productListing {
  gap: 20px;
}

.product_Listing_Page .productListing .content_right {
  width: 77%;
  flex: 0 0 77%;
  padding-left: 50px;
}

.product_Listing_Page .productListing .content_right .showBy {
  width: 100%;
  height: auto;
  background: #f1f3ff;
  padding: 15px 25px;
}

.product_Listing_Page
  .productListing
  .content_right
  .showBy
  .btnWrapper
  button {
  min-width: 35px !important;
  height: 35px !important;
  width: 35px !important;
  border-radius: 50% !important;
  color: #000;
}
.product_Listing_Page
  .productListing
  .content_right
  .showBy
  .btnWrapper
  button
  svg {
  color: #000;
  font-size: 18px !important;
  opacity: 0.3;
}

.product_Listing_Page
  .productListing
  .content_right
  .showBy
  .btnWrapper
  button.act
  svg {
  opacity: 1 !important;
}

.product_Listing_Page
  .productListing
  .content_right
  .showBy
  .btnWrapper
  button:hover
  svg {
  opacity: 1;
}

.productListing {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.productListing .productItem.four {
  width: 23%;
}
.productListing .productItem.three {
  width: 31.5%;
}
.productListing .productItem.two {
  width: 48%;
}
.productListing .productItem.one {
  width: 100%;
  display: flex;
  align-items: center;
}
.productListing .productItem.one .img_rapper {
  width: 25%;
}

.productListing .productItem.three .img_rapper,
.productListing .productItem.three .img_rapper .slick-slider,
.productItem.three .img_rapper .slick-slider .slick-slide {
  height: 325px;
}

.loading {
  width: 100%;
  height: 300px;
}

@media only screen and (min-width: 320px) and (max-width: 992px) {
  .fixedBtn {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: auto;
    padding: 15px;
    z-index: 10000;
    background: #fff;
    margin: auto !important;
  }
  .fixedBtn .col button {
    width: 100% !important;
  }

  .product_Listing_Page {
    padding-top: 15px !important;
  }

  .product_Listing_Page .productListing .content_right {
    width: 100% !important;
    flex: 0 0 100% !important;
    padding-left: 0px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 350px) {
  .product_Listing_Page .productItem {
    width: 99% !important;
  }
  .productListing .productItem.one {
    width: 100% !important;
  }
}

@media only screen and (min-width: 350px) and (max-width: 600px) {
  .product_Listing_Page .productListing {
    gap: 17px !important;
  }
  .product_Listing_Page .productItem {
    width: 47% !important;
  }
  .productListing .productItem.one {
    width: 100% !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
  .product_Listing_Page .productItem {
    width: 30% !important;
  }
  .productListing .productItem.one {
    width: 100% !important;
  }
}

@media only screen and (min-width: 767px) and (max-width: 992px) {
  .product_Listing_Page .productItem {
    width: 23% !important;
  }
  .productListing .productItem.one {
    width: 100% !important;
  }
}
