/* ----- Contact Us Section ----- */
.contact {
  margin-top: 1rem;
}

/* Contact Top Section */
.contact .contact-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.contact .contact-header h1 {
  font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--textColor);
}

.contact .contact-header p {
  width: 80%;
  font-size: 1rem;
  color: var(--textLight);
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
  font-weight: 500;
}

.contact .helpline {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  column-gap: 1rem;
  row-gap: 2rem;
}

.contact .contact-info-item {
  max-width: 300px !important;
  text-align: center;
}

.contact .contact-info-item .icon {
  display: inline-block;
}

.contact .contact-info-item .icon .fa {
  font-size: 2rem;
  background-color: var(--bgLight);
  border: 1px solid var(--borderColor);
  padding: 1rem;
  margin: 1rem;
  border-radius: 50%;
  color: var(--primaryColor);
  transition: all 0.35s ease;
  cursor: pointer;
}

.contact .contact-info-item .icon .fa:hover {
  transform: scale(1.05);
}

.contact .contact-info-item h3 {
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--textColor);
  text-transform: capitalize;
  margin: 15px 0 5px;
}

.contact .contact-info-item p a {
  font-size: 1rem;
  line-height: 25px;
  color: var(--textLight);
  font-weight: 400;
  transition: 0.25s all ease;
}

.contact .contact-info-item p a:hover {
  color: var(--textColor);
}

/* Media Query */
@media (max-width: 991px) {
  .contact .contact-info-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .contact .contact-header p {
    width: 100%;
    text-align: justify;
  }
}

@media (max-width: 767px) {
  .contact .contact-info-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
