.productDetails .productZoomBig {
  height: 350px !important;
}

.productDetailsSEction .productInfo .icon {
  width: 28%;
  flex: 0 0 28%;
}
body.light .productDetailsSEction .productInfo .icon svg {
  color: #5e5d72 !important;
}
.productDetailsSEction .productInfo .icon svg {
  font-size: 22px;
}
.productDetailsSEction .productInfo .name {
  width: 72%;
  flex: 0 0 72%;
  font-weight: 600;
  font-size: 15px;
  color: var(--textColor) !important;
}

body.light .productDetailsSEction .productInfo .name {
  color: #5e5d72 !important;
}

.productDetailsSEction .productInfo .row {
  margin-bottom: 10px !important;
}
.productDetailsSEction .productInfo .row .col-sm-9 {
  display: flex;
  align-items: center;
}
.productDetailsSEction .productInfo .row .col-sm-9 span {
  opacity: 0.8;
  font-size: 15px;
  padding-left: 20px;
}

.productDetailsSEction .productInfo .row .col-sm-9 span .row {
  margin-bottom: 0px !important;
}

.ratingSection {
  width: 500px;
}
.ratingSection .ratingrow {
  width: 100%;
  margin-bottom: 15px !important;
}
.ratingSection .ratingrow .progress {
  height: 5px !important;
  background-color: #000 !important;
}
.ratingSection .ratingrow .progress .progress-bar {
  background: #edb213 !important;
}

body.light .ratingSection .ratingrow .progress {
  background: #ccc !important;
}

.ratingSection .ratingrow .col1 {
  width: 15%;
  flex: 0 0 15%;
  display: flex;
}
.ratingSection .ratingrow .col2 {
  width: 50%;
  flex: 0 0 50%;
}
.ratingSection .ratingrow .col3 {
  width: 25%;
  flex: 0 0 25%;
  display: flex;
  padding-left: 20px;
}

.reviewsSecrion {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.reviewsSecrion .reviewsRow {
  width: 100%;
  height: auto;
  background: #071739;
  padding: 35px;
  border-radius: 10px;
  margin: 15px 0px;
}

body.light .reviewsSecrion .reviewsRow {
  background: #ededed !important;
}

.reviewsSecrion .reviewsRow p {
  padding-left: 20px;
}

.reviewsSecrion .reviewsRow.reply {
  width: 90%;
  margin-left: auto;
}

.reviewsSecrion .reviewsRow .btn-big {
  font-size: 14px !important;
  padding: 7px 20px !important;
}

.reviewsSecrion .reviewsRow .btn-big svg {
  font-size: 18px !important;
}

.userInfo h6 {
  margin-bottom: 0px;
}