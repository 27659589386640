.productZoom {
  width: 100%;
  height: auto;
  padding: 0.5rem 0;
  overflow: hidden;
  border-radius: 0 !important;
}
.productZoom .badge {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 100;
  padding: 4px 10px;
}
.productZoom .zoomSliderBig {
  width: 100% !important;
}

.productZoom .zoomSliderBig .item {
  overflow: hidden;
  
}

.productZoom .zoomSliderBig .item img {
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
  border: 1.5px solid var(--borderColor);
}
