.productDetails h2.hd {
  font-size: 26px;
  line-height: 38px;
}
.productDetails h3.hd {
  font-size: 18px !important;
}

.productDetails .btn-circle {
  min-width: 40px !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  background: #edeef5 !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  color: var(--textColor);
  padding: 5px !important;
}

.productDetails .btn-circle svg {
  color: var(--textColor) !important;
  opacity: 0.8;
  font-size: 18px;
}
.productDetails .btn-circle:hover {
  background: #ccc !important;
}

.productDetails .btn-circle svg.text-danger {
  color: #ed174a !important;
  opacity: 1 !important;
}

.productDetails .info .oldPrice {
  font-size: 16px !important;
  font-weight: bold;
  color: var(--textLight);
  text-decoration: line-through;
}
.productDetails .info .oldPrice.lg {
  font-size: 20px;
}
.productDetails .info .netPrice {
  font-size: 16px !important;
  font-weight: bold;
}
.productDetails .info .netPrice.lg {
  font-size: 20px;
}

.productDetails .productSizeAndWeight {
  width: 100%;
  height: auto;
  margin: 1rem 0;
}

.productDetails .productSizeAndWeight ul li a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  text-decoration: none !important;
  color: var(--textColor);
  font-size: 14px !important;
  transition: all 0.1s ease-in-out;
  border-radius: 4px !important;
}
.productDetails .productSizeAndWeight ul li a:hover {
  background: #ececec;
}
.productDetails .productSizeAndWeight ul li a.active {
  background: rgba(208, 174, 31, 0.35) !important;
}
.productDetails .productSizeAndWeight ul.error li a {
  border: 1px solid red !important;
}

.productDetails .detailsPageTabs {
  border-radius: 1rem !important;
  background: var(--bgLight);
  padding: 1.25rem;
  box-shadow: var(--materialShadowBottom);
}

.productDetails .detailsPageTabs .table th,
.productDetails .detailsPageTabs .table td {
  vertical-align: middle;
  font-size: 14px;
}

.productDetails .detailsPageTabs .table td p {
  margin-bottom: 0px;
}

.productDetails .detailsPageTabs .tabContent h3 {
  font-size: 20px;
  font-weight: 700;
}

.productDetails .customTabs ul li {
  margin-right: 10px !important;
}
.productDetails .customTabs ul li button {
  padding: 6px 25px !important;
  border: 1.5px solid rgba(0, 0, 0, 0.2);
  font-size: 14px !important;
  text-transform: capitalize !important;
  border-radius: 30px !important;
  color: var(--textColor) !important;
}

.productDetails .customTabs ul li button.active {
  background: var(--primaryColor) !important;
  border-color: var(--primaryColor);
  color: #fff !important;
}

.productDetails .reviewBox {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.productDetails .reviewForm h4 {
  font-size: 20px;
  margin-bottom: 15px;
  font-weight: 700;
}
.productDetails .reviewForm .form-group {
  margin-bottom: 25px;
}
.productDetails .reviewForm .form-group .form-control {
  height: 50px;
  font-size: 16px;
  border-radius: 7px !important;
  box-shadow: var(--materialShadow);
  outline: none !important;
  /* background: var(--bgLight); */
}
.productDetails .reviewForm .form-group textarea.form-control {
  height: 200px;
  padding-top: 25px;
}

.productDetails .actions_ {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
  flex-wrap: wrap;
  margin-top: 2rem;
}

@media only screen and (min-width: 200px) and (max-width: 992px) {
  .productDetails .product_row {
    margin: 0px !important;
  }

  .productDetails .res-hide {
    display: none !important;
  }

  .productDetails .part2 .hd {
    font-size: 22px !important;
    line-height: 30px !important;
  }

  .productDetails .btnActions {
    padding-top: 15px;
    position: relative;
    width: 100% !important;
  }

  .productDetails .detailsPageTabs {
    margin-top: 0px !important;
  }

  .productDetails .customTabs ul {
    white-space: nowrap !important;
    overflow: scroll !important;
    overflow-y: hidden;
    display: block !important;
    margin-bottom: 0px !important;
  }
  .productDetails .customTabs ul::-webkit-scrollbar {
    display: none !important;
  }
  .productDetails .customTabs ul li {
    display: inline-block !important;
    width: max-content !important;
  }
  .productDetails .customTabs ul li button {
    padding: 5px 15px !important;
    font-size: 13px !important;
  }
  .productDetails .tabContent table * {
    white-space: nowrap;
  }
}

@media only screen and (min-width: 320px) and (max-width: 575px) {
  .productDetails .detailsPageTabs {
    padding: 20px !important;
  }
}
