.loginSection {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: relative;
    padding: 3rem 1rem;
}

/* _____ LHS _____ */

.loginSection .left-section {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 1rem;
    padding-right: 4rem;
    margin-bottom: 4rem;
}

.loginSection .left-section .left-side-logo {
    width: 150px;
    margin-bottom: 1rem;
}

.loginSection .left-section h1 {
    font-size: 2rem;
    font-weight: 700;
}

.loginSection .left-section h1 span {
    color: var(--primaryColor);
    font-weight: 800 !important;
}

.loginSection .left-section p {
    font-size: 16px;
    font-weight: 500;
    color: var(--textLight);
    margin: 1rem 0;
    text-align: justify;
}

/* _____ RHS _____ */
.loginSection .right-logo {
    display: none;
}

.loginSection .form-btn-group {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
}

.loginSection .forgot-password {
    display: flex;
    align-items: center;
    text-align: right;
    width: 100%;
    justify-content: flex-end;
    margin-top: 4px;
    font-weight: bold;
    font-size: 14px;
}

.loginSection .or {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
}

.loginSection .or span.line {
    display: block;
    width: 35%;
    height: 1px;
    background: rgba(0, 0, 0, 0.2);
}

.loginSection .or span.txt {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    width: 40px;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 50%;
}

/* _____ Reset Password _____ */
.loginSection .reset-password {
    margin-top: 50px;
}

.loginSection .reset-password .left .lottie-animation {
    width: 80%;
}

.loginSection .reset-password .left .lottie-animation .animation {
    width: 90%;
}

/* Media Query */

@media (max-width: 1200px) {
    .loginSection .reset-password {
        margin-top: 100px;
    }
}

@media (max-width: 992px) {
    .loginSection .reset-password {
        margin-top: 0;
    }

    .loginSection .reset-password .left {
        padding-bottom: 3rem;
    }

    .loginSection .reset-password .left .lottie-animation {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .loginSection .reset-password .left .lottie-animation .animation {
        width: 80%;
    }
}

@media (max-width: 576px) {
    .loginSection {
        padding: 2rem 0;
    }

    .loginSection .left-section {
        display: none;
    }

    .loginSection .right-logo {
        display: block;
    }

    .loginSection .reset-password .left .lottie-animation .animation {
        width: 100%;
    }
}

@media (max-width: 500px) {
    .loginSection .form-btn-group {
        flex-direction: column;
    }
}