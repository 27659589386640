.section {
  padding: 45px 0px;
}

.myAccountPage .MuiTab-root {
  font-weight: 600 !important;
}

.hd {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}

.myAccBox {
  padding: 25px 0px;
  background: #fff;
  border-radius: 10px;
}

.myAccBox .form-group {
  margin-bottom: 20px;
}

.myAccBox .form-group .MuiOutlinedInput-input {
  background: #f1f1f1 !important;
}

.userImage {
  width: 140px;
  height: 140px;
  overflow: hidden;
  border-radius: 100%;
  border: 2px solid #0858f7;
  position: relative;
}

.userImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.userImage .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  opacity: 0;
}

.userImage .overlay svg {
  font-size: 40px;
  color: #fff;
}

.userImage:hover .overlay {
  opacity: 1;
}

.userImage .overlay input {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1000;
  opacity: 0;
}

@media only screen and (min-width: 320px) and (max-width: 992px) {
  .myAccBox .col-md-8 {
    margin-top: 25px !important;
  }
}