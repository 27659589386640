.right-content {
  padding: 78px 30px;
  padding-bottom: 10px;
}

.dashboardBoxWrapperRow {
  padding-top: 20px;
}

.dashboardBoxWrapperRow .box {
  width: 100%;
  height: 100%;
}

.dashboardBoxWrapperRowV2 .dashboardBox {
  width: 100%;
  height: 120px !important;
}

.dashboardBoxWrapperRowV2 .dashboardBox .bottomEle {
  display: none !important;
}

.dashboard_Box {
  justify-content: space-between;
}

.dashboard_Box .dashboardBox {
  max-width: 100%;
}

.dashboardBoxWrapper {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.dashboardBoxWrapper .dashboardBox {
  width: 100%;
  max-width: 350px;
  height: 170px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: left !important;
  position: relative;
  color: #fff;
  text-transform: capitalize !important;
}

.dashboardBoxWrapper .dashboardBox .col1 h4 {
  font-size: 16px;
  font-weight: 600;
}

.dashboardBoxWrapper .dashboardBox .col1 span {
  font-size: 35px !important;
  font-weight: bold;
  line-height: 45px;
}

.dashboardBoxWrapper .dashboardBox .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background-image: linear-gradient(to bottom right,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.3));
}

.dashboardBoxWrapper .dashboardBox .icon svg {
  font-size: 36px;
  opacity: 0.4;
  color: #fff;
}

.dashboardBoxWrapper .dashboardBox .toggleIcon {
  font-size: 22px;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  min-width: 40px !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
}

.dashboardBoxWrapper .dashboardBox .chart {
  opacity: 0.05;
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.dashboardBoxWrapper .dashboardBox .chart svg {
  font-size: 110px;
  color: #000;
}

.dashboardBoxWrapper .dashboardBox .ml-auto {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboardBoxWrapper .dashboardBox .bottomEle {
  position: relative;
  top: 15px;
}

.dashboardBoxWrapper .dashboardBox .MuiList-root svg {
  color: #000 !important;
  font-size: 17px !important;
}

.graphBox {
  background-image: linear-gradient(#1a50b5, #2a6ff7);
  border-radius: 10px;
}

.graphBox h4 {
  font-size: 16px;
  font-weight: 600;
}

.graphBox p {
  font-size: 13px;
  color: #fff;
  opacity: 0.7;
}

body.dark .cardFilters .col-md-3 h4 {
  color: rgba(255, 255, 255, 0.8);
}

.cardFilters .col-md-3 h4 {
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  color: #403e57;
}


@media (max-width: 1850px) {
  .dashboardBoxWrapper .dashboardBox {
    max-width: 300px;
  }
}

@media (max-width: 1650px) {
  .dashboardBoxWrapper .dashboardBox {
    max-width: 270px;
  }
}

@media (max-width: 1520px) {
  .dashboardBoxWrapper .dashboardBox {
    max-width: 250px;
  }
}

@media (max-width: 1400px) {
  .dashboardBoxWrapper .dashboardBox {
    max-width: 200px;
  
  }
}

@media (max-width: 1260px) {
  .dashboardBoxWrapper .dashboardBox {
    min-width: 400px;
   
  }
}

@media (max-width: 1180px) {
  .dashboardBoxWrapper .dashboardBox {
    min-width: 350px;
  }
}

@media (max-width: 1080px) {
  .dashboardBoxWrapper .dashboardBox {
    min-width: 320px;
  }
}

@media (max-width: 1020px) {
  .dashboardBoxWrapper .dashboardBox {
    min-width: 300px;
  }
}

@media (max-width: 992px) {
  .dashboardBoxWrapper .dashboardBox {
    min-width: 270px;
    max-width: 300px;
  }
}

@media (max-width: 876px) {
  .dashboardBoxWrapper .dashboardBox {
    min-width: 250px;
    max-width: 280px;
  }
}

@media (max-width: 820px) {
  .dashboardBoxWrapper .dashboardBox {
    min-width: 220px;
    max-width: 250px;
  }
}

@media (max-width: 767px) {
  .dashboardBoxWrapper .dashboardBox {
    min-width: 320px;
    max-width: 350px;
  }
}

@media (max-width: 720px) {
  .dashboardBoxWrapper .dashboardBox {
    min-width: 280px;
    max-width: 320px;
  }
}

@media (max-width: 640px) {
  .dashboardBoxWrapper .dashboardBox {
    min-width: 250px;
    max-width: 270px;
  }
}

@media (max-width: 576px) {
  .dashboardBoxWrapper .dashboardBox {
    min-width: 220px;
    max-width: 250px;
  }
}

@media (max-width: 520px) {
  .dashboardBoxWrapper .dashboardBox {
    min-width: 200px;
    max-width: 220px;
  }
}

@media (max-width: 476px) {
  .dashboardBoxWrapper .dashboardBox {
    min-width: 180px;
    max-width: 200px;
  }
}

@media (max-width: 440px) {
  .dashboardBoxWrapper .dashboardBox {
    min-width: 100%;
    max-width: 100%;
  }
}
