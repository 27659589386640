
.dashboardBoxWrapper {
  gap: 25px;
  width: 100%;
  flex-wrap: wrap;
}
.dashboardBoxWrapper .dashboardBox {
  width: 48%;
  height: 170px;
  border-radius: 10px;
  overflow: hidden;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: left !important;
  position: relative;
  color: #fff;
  text-transform: capitalize !important;
}

.dashboardBoxWrapper .dashboardBox .col1 h4 {
  font-size: 16px;
  font-weight: 600;
}
.dashboardBoxWrapper .dashboardBox .col1 span {
  font-size: 35px !important;
  font-weight: bold;
  line-height: 45px;
}

.dashboardBoxWrapper .dashboardBox .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background-image: linear-gradient(
    to bottom right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.3)
  );
}
.dashboardBoxWrapper .dashboardBox .icon svg {
  font-size: 36px;
  opacity: 0.4;
  color: #fff;
}

.dashboardBoxWrapper .dashboardBox .toggleIcon {
  font-size: 22px;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  min-width: 40px !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
}

.dashboardBoxWrapper .dashboardBox .chart {
  opacity: 0.05;
  position: absolute;
  bottom: 20px;
  left: 20px;
}
.dashboardBoxWrapper .dashboardBox .chart svg {
  font-size: 110px;
  color: #000;
}

.dashboardBoxWrapper .dashboardBox .ml-auto {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboardBoxWrapper .dashboardBox .bottomEle {
  position: relative;
  top: 15px;
}

.dashboardBoxWrapperRow {
  padding-top: 20px;
}
.dashboardBoxWrapperRow .box {
  width: 100%;
  height: 100%;
}

.dashboardBoxWrapperRowV2 .dashboardBox {
  width: 31.5%;
  height: 120px !important;
}

.dashboardBoxWrapperRowV2 .dashboardBox .bottomEle {
  display: none !important;
}

body.dark .cardFilters .col-md-3 h4 {
  color: rgba(255, 255, 255, 0.8);
}

.cardFilters .col-md-3 h4 {
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  color: #403e57;
}

.imgUploadBox {
  width: 100%;
  flex-wrap: nowrap;
  gap: 10px;
}
.imgUploadBox .uploadBox {
  width: 150px;
  height: 150px !important;
  padding: 0px;
  border-radius: 10px;
  border: 1px dashed rgba(0, 0, 0, 0.3);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #fafafa;
  transition: all 0.3s;
}

.imgUploadBox .uploadBox .box {
  width: 100%;
  height: 150px !important;
  overflow: hidden;
  border-radius: 7px;
}
.imgUploadBox .uploadBox .box .lazy-load-image-background {
  height: 100% !important;
  width: 100% !important;
}

.imgUploadBox .uploadBox .progressBar .MuiCircularProgress-root {
  width: 30px !important;
  height: 30px !important;
}
.imgUploadBox .uploadBox .progressBar span {
  font-size: 14px;
}

body.dark .imgUploadBox .uploadBox {
  background: #2b3c5f;
}
body.dark .imgUploadBox .uploadBox * {
  color: rgba(255, 255, 255, 0.7) !important;
}

.imgUploadBox .uploadBox .remove {
  position: absolute;
  top: -7px;
  right: -7px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background: #d32f2f;
  border-radius: 50%;
  cursor: pointer;
  z-index: 100;
}

.imgUploadBox .uploadBox .remove svg {
  font-size: 18px;
  color: #fff;
}

.imgUploadBox .uploadBox .MuiBox-root {
  position: absolute;
}

.imgUploadBox .uploadBox:hover {
  background: #f1f1f1;
}

body.dark .imgUploadBox .uploadBox {
  border: 1px dashed rgba(255, 255, 255, 0.2);
}

.imgUploadBox .uploadBox img {
  height: 100%;
  object-fit: cover;
}
.imgUploadBox .uploadBox input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.imgUploadBox .uploadBox .info {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  pointer-events: none;
}
.imgUploadBox .uploadBox .info svg {
  font-size: 50px;
  opacity: 0.2;
}
.imgUploadBox .uploadBox .info h5 {
  font-size: 15px;
  text-align: center;
  opacity: 0.5;
}