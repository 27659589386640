.quantityDrop {
  width: 150px;
  gap: 10px;
}
.quantityDrop button {
  min-width: 40px !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  background: #edeef5 !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  color: #000;
}
.quantityDrop button svg {
  color: #000 !important;
}

.quantityDrop button:hover {
  background: #ccc !important;
}

.quantityDrop input {
  width: 30px;
  border: 0px;
  background: transparent;
  outline: none !important;
  text-align: center;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .quantityDrop {
    width: 100% !important;
  }
}
