.countryDropdown {
  width: 170px !important;
  height: 50px;
  min-width: 170px;
  color: var(--textColor) !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
  padding: 10px 15px !important;
}
.countryDropdown:hover {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.09);
}

.countryDropdown {
  text-align: left;
  justify-content: flex-start !important;
}
.countryDropdown .label {
  font-size: 12px !important;
  text-transform: capitalize !important;
  color: var(--textLight) !important;
  line-height: 16px;
  border: none !important;
}
.countryDropdown .name {
  font-size: 14px !important;
  text-transform: capitalize !important;
  color: var(--primaryHoverColor) !important;
  font-weight: 700;
  line-height: 16px;
}

.countryDropdown span.ml-auto svg {
  opacity: 0.7;
}

.locationModal .MuiPaper-elevation {
  padding: 25px;
  position: relative;
}
.locationModal .MuiPaper-elevation h4 {
  font-size: 18px;
  font-weight: 600;
}
.locationModal .MuiPaper-elevation p {
  font-size: 13px;
}

.locationModal .countryList {
  margin-bottom: 0px;
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.locationModal .countryList li {
  width: 100%;
  list-style: none;
}
.locationModal .countryList li button {
  width: 100%;
  text-transform: capitalize !important;
  color: var(--textColor) !important;
  justify-content: flex-start !important;
  padding: 10px 20px !important;
}

.locationModal .countryList li button:hover {
  background: var(--bgLight);
}
.locationModal .countryList li button.selected {
  background: var(--primaryColor) !important;
  color: #fff !important;
}

.close_ {
  position: absolute !important;
  top: 10px;
  right: 10px;
  color: var(--textColor) !important;
  min-width: 40px !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  background: var(--bgLight) !important;
}

.close_ svg {
  font-size: 22px;
  color: var(--textColor) !important;
  opacity: 0.7;
}

@media only screen and (min-width: 320px) and (max-width: 575px) {
  .countryDropdown {
    width: 100% !important;
  }
}
