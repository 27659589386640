.product-category {
  margin: 1rem 0;
}
.product-category .item {
  padding: 12px 6px;
  width: 100%;
  min-height: 150px;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
  border: 1px solid var(--borderLight);
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  gap: 0.5rem;
  margin: 1rem 0;
}
.product-category .item .image {
  width: 70px;
  height: 70px;
  border: 1px solid var(--borderColor);
  padding: 8px;
  border-radius: 50%;
  background: var(--bgColor);
}

.product-category .item .image img {
  width: 100%;
}
.product-category .item h6 {
  font-weight: 600;
  font-size: 14px;
  color: var(--textColor);
  opacity: 0.8;
  text-transform: capitalize;
}
