.section {
  padding: 45px 0px;
}

.cartPage .table thead {
  background: #e3e3e3 !important;
  overflow: hidden;
}
.cartPage .table thead th {
  border: 0px !important;
}
.cartPage .table thead th:first-child {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
.cartPage .table thead th:last-child {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.cartPage table th,
.cartPage table td {
  vertical-align: middle;
}

.cartPage .table td,
.cartPage .table th {
  border-top: 0px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.cartPage table tbody tr:hover {
  background: #fafafa;
}

.myListTableWrapper {
  width: 70%;
  margin: auto;
}

.myListTable .cartItemimgWrapper {
  width: 500px !important;
}
.myListTable .cartItemimgWrapper .imgWrapper {
  width: 100px !important;
  height: 100px !important;
}

.cartItemimgWrapper {
  width: 280px;
}
.cartItemimgWrapper .imgWrapper {
  width: 20%;
  height: 80px;
  overflow: hidden;
}
.cartItemimgWrapper .imgWrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cartItemimgWrapper .info {
  width: 80%;
}
.cartItemimgWrapper .info a:hover {
  text-decoration: none;
}
.cartItemimgWrapper .info h6 {
  color: #343434;
  font-weight: bold;
}
.cartItemimgWrapper .info h6:hover {
  color: #ed174a !important;
}

.remove {
  cursor: pointer;
}
.remove svg {
  font-size: 30px;
}
.remove:hover svg {
  color: #ed174a !important;
}

.loadingOverlay {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 10000;
  cursor: wait;
  background: rgba(255, 255, 255, 0.7);
}

.cartPage .quantityDrop button {
  zoom: 75%;
}

@media only screen and (min-width: 320px) and (max-width: 992px) {
  .myListTableWrapper {
    width: 100% !important;
  }
  .myListTableWrapper .col-md-12 {
    padding-right: 15px !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .empty h3 {
    text-align: center;
    font-size: 18px !important;
  }
}
