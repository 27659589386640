h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: bar(--textColor);
}

.full {
  max-width: 100% !important;
  flex: 0 0 100% !important ;
  width: 100% !important;
}

.dot {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 100%;
}

.addBtn {
  min-width: 30px !important;
  height: 30px !important;
}

.badge {
  padding: 4px;
  font-size: 13px;
  font-weight: 400;
}

.cursor {
  cursor: pointer;
}

.right-content {
  padding: 78px 30px;
  padding-bottom: 10px;
}

.headerSearchWrapper {
  width: 58%;
}

.main .content {
  width: 80%;
  flex: 0 0 80%;
  transition: all 0.3s;
}
.main .content.toggle {
  width: 100%;
  flex: 0 0 100%;
}

.slick-slide {
  outline: none !important;
}

p {
  font-weight: 300;
  font-size: 15px;
  line-height: 25px;
}

body.light p {
  font-weight: 400 !important;
}

/* _____ Text _____ */
.text-sky {
  color: var(--primaryColor) !important;
  font-weight: 500 !important;
}
.text-danger {
  color: #ff6179 !important;
}

/* _____ Button _____ */
.btn-blue {
  background: var(--primaryColor) !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #fff !important;
  text-transform: capitalize !important;
  border-radius: 5px !important;
  border-radius: 8px !important;
}
.btn-lg {
  padding: 5px 25px !important ;
}
.btn-big {
  padding: 10px 25px !important;
  font-size: 16px !important;
}
.btn-big svg {
  font-size: 25px !important;
}
.btn-round {
  border-radius: 30px !important;
}
.btn-blue svg {
  color: #fff !important;
}

button .loader {
  width: 25px !important;
  height: 25px !important;
}
button.error {
  background-color: #ff6179 !important;
}
button.error svg {
  color: #fff !important;
}
.inputBtn button {
  position: absolute;
  top: 7px;
  right: 10px;
  z-index: 100;
}
button.notAllow {
  cursor: no-drop !important;
}

/* _____ Link _____ */
.link {
  color: #5e5d72;
  font-weight: 800;
}
.link:hover {
  color: var(--primaryColor) !important;
  text-decoration: none;
}
.link.color {
  color: var(--primaryColor) !important;
  text-decoration: none;
}

/* _____ Tags _____ */
.tags {
  margin-bottom: 0px !important;
  padding-left: 8px;
}
.tags li span {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #121d37 !important;
  border-radius: 5px !important;
  font-size: 12px !important;
  padding: 3px 10px !important;
}
.tags li {
  margin: 3px 5px !important;
}
body.light .tags li span {
  background: #d8d8d8 !important;
  color: var(--textColor) !important;
  padding: 4px 10px !important;
}

/* _____ Card _____ */
.card {
  margin: 20px 0px;
  border-radius: 10px;
}
.card .hd {
  font-size: 18px;
  font-weight: bold;
  color: #403e57;
}

/* _____ Top loading bar _____ */
.topLoadingBar {
  height: 4px !important;
  background: var(--primaryColor) !important;
}

/* _____ Img grid _____ */
.imgGrid {
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
}
.imgGrid .img {
  width: 47%;
  height: 100px;
  overflow: hidden;
  border-radius: 7px;
}
.imgGrid .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* _____ Slider _____ */
.sliderSml .slick-current .item {
  border: 1px dashrgb(128, 128, 128) ccc !important;
}
.sliderSml .slick-slide {
  padding-right: 10px;
}
.sliderSml .slick-slide .item {
  border-radius: 7px !important;
  overflow: hidden;
}
.sliderSml *,
.sliderBig * {
  transition: all 0.2s !important;
}
.sliderBig .item {
  overflow: hidden;
  border-radius: 7px;
  border: 2px dashed #ccc !important;
}
.sliderSml .item {
  overflow: hidden;
  border-radius: 7px;
  border: 2px dashed #ccc !important;
  cursor: pointer;
}

/* _____ Close _____ */
.close {
  opacity: 1 !important;
}
.close_ {
  position: absolute !important;
  top: 10px;
  right: 10px;
  color: var(--textColor) !important;
  min-width: 40px !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
  background: #f1f1f1 !important;
}
.close_ svg {
  font-size: 22px;
  color: var(--textColor) !important;
  opacity: 0.7;
}
.closeSearch {
  display: none;
}

/* _____ Form _____ */
form .btn-blue {
  position: relative;
  z-index: 100;
}
.form .form-group {
  margin-bottom: 25px;
}
.form .form-group h6 {
  margin-bottom: 10px;
  font-size: 13px;
}
.form .form-group input,
.form .form-group textarea {
  background: #fafafa;
  width: 100%;
  outline: none !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  height: 55px;
  padding-left: 15px;
  border-radius: 5px;
  font-size: 14px;
}
.form .form-group input:focus {
  border: 1px solid rgba(0, 0, 0, 0.4) !important;
}
.form .form-group input:hover,
.form .form-group textarea:hover {
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
}
.form .form-group textarea {
  height: 120px;
  padding-top: 15px;
}
form .form-group h6 {
  font-weight: 600;
}

/* _____ Table _____ */
.table-responsive::-webkit-scrollbar-thumb {
  background: #ccc !important;
}
.table th {
  white-space: nowrap;
}

.table tbody td {
  font-size: 14px !important;
  color: #2f2d3e;
  font-weight: 500;
}
.table tbody .actions button {
  min-width: 32px !important;
  width: 32px !important;
  height: 32px !important;
  margin: 0px 3px !important;
}
.table tbody .actions button.secondary {
  background: rgba(203, 60, 231, 0.2) !important;
}
.table tbody .actions button.success {
  background: rgba(26, 159, 83, 0.2) !important;
}
.table tbody .actions button svg {
  font-size: 30px !important;
}
table.v-align td {
  vertical-align: middle !important;
}
.table td .old,
.table td .new {
  display: block;
  width: 100%;
}
table td .imgWrapper {
  width: 20%;
  flex: 0 0 20%;
}
table td .imgWrapper .card {
  border-radius: 10px !important;
  overflow: hidden !important;
}
table td .info {
  width: 65%;
  flex: 0 0 65%;
}
table td .info h6 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0px;
}
table td .info p {
  font-size: 13px;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #5e5d72;
  margin-bottom: 0px;
}
table.v-align td .productBox {
  width: 270px;
}

/* _____ Table footer _____ */
.tableFooter {
  padding-bottom: 20px;
}
.tableFooter p {
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 500;
}
.tableFooter .pagination {
  margin-left: auto;
}
.tableFooter p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
}

/* _____ Breadcrumbs _____ */
.breadcrumbs_ a:hover {
  text-decoration: none !important;
}

/* _____ Over-ridded classes _____ */
.dropdown_menu .MuiButtonBase-root svg {
  margin-right: 10px !important;
}
button .MuiCircularProgress-root {
  width: 30px !important;
  height: 30px !important;
}
.rti--tag button {
  color: #fff !important;
}
.MuiDialog-paper button {
  font-weight: 600 !important;
}
.css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: var(--primaryColor) !important;
}
.editModal .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  width: 600px !important;
}
.rti--container {
  background: #2b3c5f !important;
  border: 0px !important;
}
.rti--tag {
  background: #364e7d !important;
}
