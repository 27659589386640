.productItem {
  width: 100%;
  height: auto;
  border: 1px solid var(--borderLight);
  cursor: pointer;
  position: relative;
  transition: all 0.25s;
  background: var(--bgLight);
  border-radius: 0.5rem !important;
  text-decoration: none;
  color: var(--textColor);
  display: block;
  overflow: hidden;
  transition: all 0.35s ease;
}

.productItem:hover {
  text-decoration: none;
}

.productItem .badge {
  display: inline-block;
  padding: 7px 14px;
  position: absolute;
  background: var(--secondaryColor);
  border: 1px solid var(--primaryColor);
  top: 10px;
  left: 10px;
  z-index: 100;
  color: var(--textColor);
  font-weight: 500;
}

.productItem .info {
  padding: 15px;
}
.productItem .info h4 {
  font-size: 15px;
  font-weight: 600;
  color: var(--textColor) !important;
  transition: all 0.2s ease-in-out;
}
.productItem .info h4:hover {
  color: var(--primaryHoverColor) !important;
}
.productItem a:hover {
  text-decoration: none;
}

.productItem .actions {
  position: absolute;
  width: 50px;
  height: 100%;
  top: 20px;
  right: 0%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 10px;
  transition: all 0.5s ease-in-out;
  opacity: 0;
}
.productItem .actions button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px !important;
  height: 40px !important;
  width: 40px !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 50% !important;
  background: #fff !important;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%) !important;
  transition: all 0s !important;
}

.productItem .actions button svg {
  color: #000 !important;
  opacity: 0.8;
  pointer-events: none;
  font-size: 15px;
}

.productItem .actions button:hover {
  background: var(--secondaryColor) !important;
  border: 1px solid var(--secondaryColor) !important;
}
.productItem .actions button:hover svg {
  color: var(--textColor) !important;
  opacity: 1 !important;
}

.productItem .actions button.active {
  background: var(--secondaryColor) !important;
  border: 1px solid var(--secondaryColor) !important;
}
.productItem .actions button.active svg {
  color: #fff !important;
  opacity: 1 !important;
}

.productItem .img_rapper {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 220px;
  border-radius: 7px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background: #fff;
}
.productItem .img_rapper a {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}
.productItem .img_rapper .slick-slider {
  padding: 0px !important;
  width: 100% !important;
  height: 220px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.productItem .img_rapper .slick-slider img {
  width: 100%;
}

.productItem .img_rapper .slick-slider .slick-slide {
  overflow: hidden !important;
  padding: 0px !important;
  height: 220px;
  transition: all 0.3s ease;
}

.productItem .img_rapper .slick-dots {
  bottom: 0px !important;
  padding: 15px 0px;
  z-index: 100;
}
.productItem .img_rapper .slick-dots li {
  width: 7px !important;
}
.productItem .img_rapper .slick-dots li button {
  opacity: 1;
}

.productItem .img_rapper .slick-dots li button:before {
  color: #fff !important;
}

.productItem .img_rapper img {
  transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  height: 100%;
  object-fit: cover;
}
.productItem:hover .actions {
  opacity: 1;
}
.productItem:hover .img_rapper img {
  transform: scale(1.1) !important;
}

.productItem .img_rapper .MuiSkeleton-root {
  width: 100% !important;
  height: 220px !important;
  position: relative;
  z-index: 1000 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.productItem .img_rapper .MuiSkeleton-root svg {
  font-size: 70px !important;
  visibility: inherit !important;
  color: var(--textColor) !important;
  opacity: 0.1 !important;
}

.productItem:hover .btn-blue.w-100 {
  opacity: 1;
}

.productItemSliderWrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 100;
  transition: all 0.5s;
  overflow: hidden;
  opacity: 0;
}
.productItem:hover .productItemSliderWrapper {
  opacity: 1;
}
.productItemSliderWrapper .productItemSlider {
  position: relative;
}
.productItemSliderWrapper::after {
  content: "";
  width: 100%;
  height: 100%;
  z-index: 10000;
  position: absolute;
  bottom: 0px;
  left: 0px;
  opacity: 0.1;
  pointer-events: none;
  background: var(--textColor);
  opacity: 0.2;
}

.info .oldPrice {
  font-size: 16px !important;
  font-weight: bold;
  color: var(--textLight);
  text-decoration: line-through;
}

.info .netPrice {
  font-size: 16px !important;
  font-weight: bold;
}

@media only screen and (min-width: 320px) and (max-width: 992px) {
  .productItem .info h4 {
    white-space: initial !important;
  }
}
