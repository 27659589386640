.signInPage {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.signInPage .shape-bottom {
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.signInPage::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(
    var(--primaryColor),
    var(--primaryHoverColor)
  );
  opacity: 0.9;
  z-index: -1;
}

.signInPage .box {
  max-width: 500px;
  height: auto;
  background: var(--bgLight);
  margin: auto;
  position: relative;
  z-index: 100;
}

.signInPage .box h2 {
  font-size: 25px;
  font-weight: 600;
}

.signInPage .box .form-group {
  margin-bottom: 15px;
}

.signInPage .box .txt {
  font-size: 14px;
  font-weight: 500;
}

.loginWithGoogle {
  font-weight: bold !important;
  color: var(--textColor) !important;
}
.loginWithGoogle img {
  width: 30px;
  margin-right: 10px;
}

@media only screen and (min-width: 320px) and (max-width: 992px) {
  .signInPage {
    height: auto !important;
  }
  .signInPage .box {
    width: 100% !important ;
  }
  .signInPage .box form button {
    margin-bottom: 10px;
  }
}
