.product_Listing_Page .productListing .sidebar {
  width: 20%;
  flex: 0 0 20%;
}

.product_Listing_Page .productListing .sidebar .filterBox {
  margin-bottom: 35px;
}
.product_Listing_Page .productListing .sidebar .filterBox h6 {
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 15px;
}

.product_Listing_Page .productListing .sidebar .scroll {
  padding-left: 10px;
  max-height: 180px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.product_Listing_Page .productListing .sidebar .scroll::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}
.product_Listing_Page .productListing .sidebar .scroll ul {
  margin-bottom: 0px;
}
.product_Listing_Page .productListing .sidebar .scroll ul li {
  list-style: none;
  margin-bottom: 0px;
  width: 100%;
}
.product_Listing_Page .productListing .sidebar .scroll ul li label {
  margin-bottom: 0px !important;
}
.product_Listing_Page .productListing .sidebar .scroll ul li * {
  text-transform: capitalize !important;
}

.product_Listing_Page .productListing .sidebar .scroll label {
  margin-bottom: 0px !important;
}

@media only screen and (min-width: 200px) and (max-width: 992px) {
  .product_Listing_Page .productListing .sidebar {
    position: fixed;
    top: 0px !important;
    right: -100%;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    overflow-y: scroll;
    z-index: 10000;
    background: #fff;
    flex: 0 0 100% !important;
    padding: 25px;
    transition: all 0.3s ease-in-out;
    opacity: 0;
  }
  .product_Listing_Page .productListing .sidebar.open {
    right: 0px !important;
    opacity: 1 !important;
  }
  .product_Listing_Page .productListing .sidebar img {
    display: none !important;
  }
}
