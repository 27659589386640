
.countryDrop {
  width: 100%;
  height: 50px;
  min-width: 170px;
  color: #000 !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  border-radius: 4px !important;
  padding: 10px 15px !important;
  box-shadow:var(--shadowAll);
}

.countryDrop:hover {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.09);
}

body.light .countryDrop * {
  color: rgba(0, 0, 0, 0.8) !important;
}
body.light .countryDrop {
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
}

.countryDrop {
  text-align: left;
  justify-content: flex-start !important;
}
.countryDrop .label {
  font-size: 12px !important;
  text-transform: capitalize !important;
  color: rgba(255, 255, 255, 0.7) !important;
  line-height: 16px;
}

body.light .countryDrop .label {
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.countryDrop .name {
  font-size: 14px !important;
  text-transform: capitalize !important;
  color: rgb(0, 0, 0) !important;
  font-weight: 500;
  line-height: 16px;
}

.countryDrop span.ml-auto svg {
  opacity: 0.7;
}



.locationModal .MuiPaper-elevation {
  padding: 25px;
  position: relative;
}
.locationModal .MuiPaper-elevation h4 {
  font-size: 18px;
  font-weight: 600;
}
.locationModal .MuiPaper-elevation p {
  font-size: 13px;
}

.locationModal .countryList {
  margin-bottom: 0px;
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.locationModal .countryList li {
  width: 100%;
  list-style: none;
}
.locationModal .countryList li button {
  width: 100%;
  text-transform: capitalize !important;
  color: #000 !important;
  justify-content: flex-start !important;
  padding: 10px 20px !important;
  font-weight: 500 !important;
}

body.dark .locationModal .countryList li button {
  color: #fff !important;
}

.locationModal .countryList li button:hover {
  background: rgba(255, 255, 255, 0.1);
}
.locationModal .countryList li button.active {
  background: #1976d2 !important;
  color: #fff !important;
}

.headerSearch {
  width: 90%;
  height: 50px;
  background: #e7e7e7;
  padding: 5px;
  position: relative;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}
.headerSearch input {
  background: transparent !important;
  outline: none !important;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 37px;
  border: 0px !important;
  padding: 0px 20px !important;
}

.headerSearch input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.headerSearch button {
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 100;
  min-width: 40px !important;
  height: 40px !important;
  width: 40px !important;
  border-radius: 50% !important;
  color: #000;
}
.headerSearch button svg,
header .part3 .circle svg {
  font-size: 25px;
  opacity: 0.8;
  color: #000 !important;
}

.headerSearch button .MuiCircularProgress-root {
  width: 25px !important;
  height: 25px !important;
}

body.dark .headerSearch button svg {
  color: #000 !important;
}