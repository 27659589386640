.drawer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: var(--bgLight);
}

.drawer .drawerHeader {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: flex-start;
  gap: 0.625rem;
  padding: 12px 16px;
  background: var(--bgLight);
  color: var(--primaryColor);
}

.drawer .drawerContent {
  flex-grow: 1;
  overflow-y: auto;
}

.drawer .drawerContent::-webkit-scrollbar {
  display: none !important;
}

.drawer .drawerContent::-webkit-scrollbar-thumb {
  background: #c8c8c8 !important;
  cursor: pointer;
}

.drawer .drawerContent::-webkit-scrollbar-track {
  background: var(--bgLight);
}

.drawer .listItemIcon {
  min-width: unset;
  margin-right: 12px;
}

/* override */
.drawer .css-10hburv-MuiTypography-root {
  font-size: 14px !important;
  color: var(--textHeading) !important;
}

.drawer .css-i4bv87-MuiSvgIcon-root {
  width: 1.25rem !important;
  height: 1.25rem !important;
  color: var(--textHeading) !important;
}

.drawer .activeButton {
  background: var(--dashboardActiveLinkBg) !important;
  color: var(--primaryHoverColor) !important;
  border-right: 2px solid var(--primaryColor) !important;
}

.drawer .activeButton .listItemIcon {
  color: var(--primaryHoverColor) !important;
}

.drawer .subButton {
  padding-left: 2rem;
}

.drawer .submenuContainer {
  border-top: 1px solid #dae1ea;
  border-bottom: 1px solid #dae1ea;
  padding: 1.25rem 0;
}

.drawer .firstSubmenuContainer {
  border-top: none !important;
}

.drawer .submenuContainer.lastSubmenuContainer {
  border-bottom: none !important;
}

.drawer .submenuTitle {
  padding-left: 0.75rem;
  color: #94a3b8;
  display: inline-block;
  margin-bottom: 0.5rem;
}
