.productModal .actions button {
  opacity: 1 !important;
}
.productModal .actions button svg.text-danger {
  color: #ed174a !important;
  opacity: 1 !important;
}

.productModal .MuiPaper-elevation {
  padding: 25px;
  position: relative;
  max-width: 900px !important;
  width: 900px !important;
}

.productModal h4 {
  font-size: 20px !important;
  color: rgba(0, 0, 0, 0.8) !important;
}

.orderTable th,
.orderTable td {
  white-space: nowrap;
}

.orderTable .img {
  width: 70px;
  height: 40px;
  overflow: hidden;
}
.orderTable .img img {
  width: 100%;
}