.get-in-touch {
  width: 100%;
  height: auto;
  background-image: linear-gradient(
    to bottom right,
    rgba(255, 211, 1, 0.35),
    rgba(208, 174, 31, 0.6)
  );
  position: relative;
  overflow: hidden;
  padding: 1rem;
  border-radius: 1rem;
}

.get-in-touch .info {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.get-in-touch .info h2 {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  font-weight: 600;
  color: var(--textColor);
}
.get-in-touch .info h3 {
  font-size: 1rem;
  font-weight: 500;
  color: var(--textColor);
  line-height: 1.5rem;
  margin-bottom: 1rem;
}

.get-in-touch .info h3 a {
  font-weight: 400;
  color: var(--textLight);
  transition: 0.25s all ease;
}

.get-in-touch .info h3 a:hover {
  color: var(--textColor);
}

.get-in-touch .lottie-animation {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.get-in-touch .lottie-animation .animation {
  width: 80%;
}

@media (max-width: 992px) {
  .get-in-touch {
    padding: 2.5rem 1rem;
    border-radius: 1rem;
  }

  .get-in-touch .lottie-animation .animation {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .get-in-touch .lottie-animation {
    justify-content: center;
  }
}

@media (max-width: 576px) {
  .get-in-touch {
    border-radius: 0;
  }
}

@media (max-width: 400px) {
  .get-in-touch .lottie-animation {
    margin-bottom: 1rem;
  }

  .get-in-touch .info h2 {
    font-size: 1.35rem;
  }
}
