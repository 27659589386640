nav .navPart1 {
  max-width: 20%;
  flex: 0 0 20%;
}

nav .navPart1 .catWrapper {
  position: relative;
}
.sidebarNav{
  background-color: var( --bgBody);
}
nav .navPart1 .catWrapper .sidebarNav {
  width: 100%;
  height: 0px;
  opacity: 0;
  position: absolute;
  top: 130%;
  left: 0px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 15px 0px;
  transition: all 0.2s;
  visibility: hidden;
  z-index: 100;
}

nav .navPart1 .catWrapper .sidebarNav.open {
  height: auto;
  opacity: 1;
  visibility: inherit;
}

nav .navPart1 .catWrapper .sidebarNav ul li {
  width: 100%;
  list-style: none;
  padding: 0px 10px;
}
nav .navPart1 .catWrapper .sidebarNav ul li button {
  text-align: left !important;
  color: var(--textColor);
  width: 100%;
  justify-content: flex-start;
  text-transform: capitalize !important;
}

nav .navPart1 .catWrapper .sidebarNav ul li button svg {
  font-size: 12px;
  opacity: 0.6;
}

nav .navPart1 .catWrapper ul li button:hover {
  background: transparent !important;
  color: var(--primaryHoverColor) !important;
}

nav .navPart1 .catWrapper .sidebarNav ul li:hover button:first-child {
  color: var(--primaryHoverColor) !important;
}

nav .navPart1 .catWrapper .sidebarNav ul li {
  position: static;
}

nav .navPart1 .catWrapper .sidebarNav ul li .submenu {
  position: absolute;
  top: 0px;
  left: 105%;
  width: 250px;
  height: 100%;
  background: #fff;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px;
}

nav .navPart1 .catWrapper .sidebarNav ul li:hover .submenu {
  opacity: 1;
  visibility: inherit;
  left: 100%;
}

nav .navPart1 .catWrapper .sidebarNav ul li:hover .submenu button {
  border-radius: 0px !important;
  color: #000 !important;
}

nav .navPart1 .catWrapper .sidebarNav ul li .submenu button:hover {
  color: var(--primaryHoverColor) !important;
}

.countryDrop span.ml-auto svg {
  opacity: 0.7;
}

nav .navPart2 {
  max-width: 80%;
  flex: 0 0 80%;
}

nav .navPart2 ul {
  margin-bottom: 0px;
}

nav .navPart2 ul li {
  padding-bottom: 20px;
  margin: 0 0.5rem !important;
}
nav .navPart2 ul li a {
  text-decoration: none;
}

nav .navPart2 ul li a button {
  font-size: 14px !important;
  font-weight: 600;
  text-decoration: none;
  color: var(--textLight);
  text-transform: uppercase !important;
  padding: 6px 16px;
  transition: all 0.25s ease;
}
nav .navPart2 ul li a button:hover{
  background: #f1f1f1;
  color: var(--textColor);
}

nav .navPart2 ul li a.active button {
  background: #e1e1e1;
  color: var(--textColor);
}

nav .navPart2 ul li a svg {
  font-size: 20px;
}

nav .navPart2 ul li a:hover {
  background: #f0faff;
  color: var(--primaryHoverColor);
}

nav .navPart2 ul li {
  position: relative;
}

nav .navPart2 ul li .submenu {
  position: absolute;
  top: 150%;
  left: 0px;
  min-width: 200px;
  background: #fff;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
  z-index: 100;
  overflow: hidden;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
nav .navPart2 ul li .submenu a {
  display: block;
  width: 100%;
}
nav .navPart2 ul li .submenu a button {
  text-align: left !important;
  width: 100%;
  justify-content: flex-start !important;
  border-radius: 0px !important;
  text-transform: capitalize !important;
  font-weight: 500 !important;
  color: #282828 !important;
  background: #fff;
}

nav .navPart2 ul li .submenu a button:hover {
  background: #f1f1f1 !important;
}

nav .navPart2 ul li:hover .submenu {
  opacity: 1;
  visibility: inherit;
  top: 100%;
}

.close {
  opacity: 1 !important;
}

.headerSearch {
  width: 90%;
  height: 50px;
  background: #e7e7e7;
  padding: 5px;
  position: relative;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}
.headerSearch input {
  background: transparent !important;
  outline: none !important;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 37px;
  border: 0px !important;
  padding: 0px 20px !important;
}

.headerSearch input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.headerSearch button {
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 100;
  min-width: 40px !important;
  height: 40px !important;
  width: 40px !important;
  border-radius: 50% !important;
  color: #000;
}
.headerSearch button svg,
header .part3 .circle svg {
  font-size: 25px;
  opacity: 0.8;
  color: #000 !important;
}

.headerSearch button .MuiCircularProgress-root {
  width: 25px !important;
  height: 25px !important;
}

.headerWrapperFixed {
  background: #fff;
  transition: all 0.3s ease-in-out;
  position: fixed;
  top: 34px;
  left: 0px;
  width: 100%;
  z-index: 10000;
}
.headerWrapperFixed {
  background: #fff;
  transition: all 0.3s ease-in-out;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 10000;
}
.headerWrapperFixed.fixed {
  top: -80px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 6%), 0 1px 2px rgb(0 0 0 / 12%);
}

.headerWrapperFixed + div {
  margin-top: 210px !important;
}
.headerWrapperFixed + section {
  margin-top: 195px !important;
}

.headerWrapper .top-strip {
  padding: 8px 0px;
}
.headerWrapper .top-strip p {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
}

.headerWrapper .header {
  width: 100%;
  height: auto;
  padding: 25px 0px;
}

.headerWrapper {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
}

.headerSearchWrapper {
  width: 58%;
}

.logoWrapper .nav-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  color: var(--primaryColor);
  transition: color 0.3s;
  font-family: "Varela Round", sans-serif;
  font-style: normal;
}

.logoWrapper .nav-logo img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid var(--borderColor);
  background: rgba(0, 0, 0, 0.8);
}

.logoWrapper .nav-logo small {
  font-size: 0.8rem;
  font-weight: 600;
  color: var(--textColor);
}

.closeSearch {
  display: none;
}

header .part3 .circle {
  align-items: center;
  justify-content: center;
  min-width: 45px !important;
  height: 45px !important;
  width: 45px !important;
  border-radius: 50% !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  color: #000;
  background: #ededed;
}
header .part3 .circle svg {
  font-size: 22px;
}

.cartTab .marginLeft {
  margin-left: 24px;
}

.cartTab .marginRight {
  margin-right: 24px !important;
}

.cartTab .price {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 700;
}
.cartTab button {
  background: #fff1ee !important;
}
.cartTab button svg {
  color: #ea2b0f !important;
}
.cartTab .count {
  position: absolute;
  top: -6px;
  right: -4px;
  background: #ea2b0f;
  border-radius: 50%;
  color: #fff;
  width: 20px;
  height: 20px;
}

#accDrop {
  z-index: 10000 !important;
}
#accDrop ul a {
  color: #000;
}
#accDrop ul li {
  font-size: 16px !important;
  padding: 7px 15px !important;
}
#accDrop ul li svg {
  font-size: 15px !important;
}

@media (max-width: 1200px) {
  .logoWrapper .nav-logo small {
    display: none;
  }
}

@media only screen and (min-width: 280px) and (max-width: 992px) {
  .res-hide {
    display: none !important;
  }

  .headerWrapper .header {
    padding: 10px 0px;
  }
  .headerWrapper .header .logoWrapper {
    width: 30% !important;
    padding-right: 0px !important;
  }
  .headerWrapper .header .part2 {
    width: 70% !important;
  }
  .headerWrapper .header .part2 .btn-blue {
    padding: 5px 10px !important;
    margin-right: 10px !important;
  }

  .headerWrapperFixed.fixed {
    top: 0px !important;
  }

  .res-nav {
    position: fixed;
    bottom: -100%;
    right: 0%;
    width: 100%;
    height: 100vh;
    background: #fff;
    z-index: 101;
    padding: 20px 0px;
    transition: all 0.2s ease-in-out;
    opacity: 0;
    max-height: 80vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
  }

  .res-nav .btn-blue {
    border-radius: 5px !important;
    text-align: center !important;
    justify-content: center !important;
  }

  /* For mobile drawer */

  .res-nav-overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    background: rgba(0, 0, 0, 0.7);
    z-index: 100;
    opacity: 0;
    visibility: hidden;
  }

  .res-nav-wrapper.open .res-nav {
    bottom: 0px;
    opacity: 1;
  }
  .res-nav-wrapper.open .res-nav-overlay {
    opacity: 1;
    visibility: inherit;
  }

  .res-nav li {
    width: 95vw;
    padding-bottom: 0px !important;
  }
  .res-nav li a,
  .res-nav li a button {
    display: flex !important;
    width: 100% !important;
    justify-content: flex-start !important;
  }
  .res-nav li a button {
    padding: 10px 20px !important;
  }

  nav .navPart2 ul li .submenu {
    width: 100% !important;
    position: static !important;
    height: 0px;
    box-shadow: none !important;
    transition: all 0.3s;
  }
  nav .navPart2 ul li .submenu.open {
    height: auto !important;
    opacity: 1 !important;
    visibility: inherit !important;
  }

  nav .navPart2 ul li svg {
    position: absolute;
    top: 15px;
    right: 20px;
    z-index: 100;
    transition: all 0.3s ease-in-out;
  }
  nav .navPart2 ul li svg.rotate {
    transform: rotate(180deg);
  }

  .headerSearchWrapper {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100% !important;
    height: 100vh;
    background: #fff;
    z-index: 1000;
    padding: 15px !important;
    margin: 0px !important;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }

  .headerSearchWrapper.open {
    opacity: 1;
    visibility: inherit;
  }

  .headerSearchWrapper .headerSearch {
    width: 100% !important;
    pad: 0px !important;
    margin: 0px !important;
  }

  nav .navPart1 .catWrapper .sidebarNav {
    opacity: 1 !important;
    visibility: inherit !important;
    position: relative !important;
    top: 0px !important;
    left: 0px !important;
    height: auto !important;
    display: block !important;
    border: 0px !important;
    padding: 0px !important;
  }

  nav .navPart1 {
    max-width: 100% !important;
    width: 100% !important;
  }

  nav .navPart1 .catWrapper .sidebarNav ul {
    display: block !important;
    width: 100% !important;
    white-space: nowrap !important;
    overflow: scroll !important;
    overflow-y: hidden !important ;
    margin-bottom: 0px !important;
  }
  nav .navPart1 .catWrapper .sidebarNav ul::-webkit-scrollbar {
    display: none !important;
  }

  nav .navPart1 .catWrapper .sidebarNav ul li {
    display: inline-block !important;
    vertical-align: top !important;
    width: max-content !important;
    padding: 0px !important;
  }

  .headerWrapperFixed + div {
    margin-top: 160px !important;
  }

  .headerWrapperFixed + section {
    margin-top: 140px !important;
  }

  .closeSearch {
    display: block;
    font-size: 20px;
  }
}

@media only screen and (min-width: 280px) and (max-width: 768px) {
  header .part3 .circle {
    min-width: 35px !important;
    width: 35px !important;
    height: 35px !important;
    /* margin-right: 8px !important; */
  }

  .cartTab .position-relative.ml-2 {
    margin-left: 0px !important;
  }

  #accDrop ul li {
    padding: 5px 12px !important;
    font-size: 14px !important;
  }
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgb(184, 184, 184) !important;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #fff !important;
}

@media (max-width: 400px) {
  .res-nav li {
    width: 90vw;
  }
}
